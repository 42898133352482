import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "about-buttons",
      "style": {
        "position": "relative"
      }
    }}>{`About Buttons`}</h2>
    <p>{`Buttons should be used in situations where users might need to take actions:`}</p>
    <ul>
      <li parentName="ul">{`submitting a form`}</li>
      <li parentName="ul">{`specify a new or next step in a flow`}</li>
    </ul>
    <h2 {...{
      "id": "button-variations",
      "style": {
        "position": "relative"
      }
    }}>{`Button variations`}</h2>
    <h3 {...{
      "id": "primary-button",
      "style": {
        "position": "relative"
      }
    }}>{`Primary Button`}</h3>
    <p>{`Primary buttons are used to indicate a primary action within a page or view.
Primary buttons should only be used once per page, as overuse can cause
confusion for a user. However not every page or view needs a primary button.`}</p>
    <h3 {...{
      "id": "secondary-button",
      "style": {
        "position": "relative"
      }
    }}>{`Secondary Button`}</h3>
    <p>{`The secondary button is used for medium emphasis. It should be used adjacent to
a primary button when the action requires less prominence, or if there are
multiple actions of the same importance on the page.`}</p>
    <h3 {...{
      "id": "outline-button",
      "style": {
        "position": "relative"
      }
    }}>{`Outline Button`}</h3>
    <p>{`Used for general actions, navigation, and filtering.`}</p>
    <h3 {...{
      "id": "plain-button",
      "style": {
        "position": "relative"
      }
    }}>{`Plain Button`}</h3>
    <p>{`When a button needs to be placed on top of a colored background or a visual, use
the plain button. This button's appears in a white background.`}</p>
    <p>{`A plain button can also be used in situations where you need a more quiet style
button due to its white background.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best Practices`}</h2>
    <h3 {...{
      "id": "button-labels-should-be-written-in-sentence-case",
      "style": {
        "position": "relative"
      }
    }}>{`Button labels should be written in sentence case`}</h3>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=491%3A217" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "avoid-using-multiple-primary-buttons-on-a-page-or-view",
      "style": {
        "position": "relative"
      }
    }}>{`Avoid using multiple primary buttons on a page or view`}</h3>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=491%3A227" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "use-an-icon-to-convey-more-clarity-they-should-not-be-used-for-decoration",
      "style": {
        "position": "relative"
      }
    }}>{`Use an icon to convey more clarity. They should not be used for decoration.`}</h3>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=491%3A280" mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      